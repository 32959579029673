import Vue from "vue";

import VueSanitize from "vue-sanitize";

let defaults = VueSanitize.defaults;

defaults.allowedTags = [...defaults.allowedTags, 'style']
defaults.allowedTags = [...defaults.allowedTags, 'img']
defaults.allowedTags = [...defaults.allowedTags, 'caption']
defaults.allowedTags = [...defaults.allowedTags, 'br']
defaults.allowedTags = [...defaults.allowedTags, 'map']
defaults.allowedTags = [...defaults.allowedTags, 'area']
defaults.allowedTags = [...defaults.allowedTags, 'iframe']
defaults.allowedTags = [...defaults.allowedTags, 'noscript']
defaults.allowedTags = [...defaults.allowedTags, 'font']

const aTageAllowAttr = [...defaults.allowedAttributes.a]

for(const index in defaults.allowedTags) {
  const tagNm = defaults.allowedTags[index];
  const tagAttr = defaults.allowedAttributes[tagNm]
  if(tagAttr != null){
    if(tagNm == 'a') {
      defaults.allowedAttributes[tagNm] = [...defaults.allowedAttributes[tagNm], 'class','style', 'rel', 'href','width','summary','onclick','id']
    }else if(tagNm == 'img'){
      defaults.allowedAttributes[tagNm] = [...defaults.allowedAttributes[tagNm], 'class','style','width','summary', 'name','id','usemap']
    }else{
      defaults.allowedAttributes[tagNm] = [...defaults.allowedAttributes[tagNm], 'class','style','width','summary', 'name','id']
    }
  }else{
    if(tagNm == 'th') {
      defaults.allowedAttributes[tagNm] = ['class','style', 'colspan', 'rowspan','width','summary','id', 'scope']
    }else if (tagNm == 'td') {
      defaults.allowedAttributes[tagNm] = ['class','style', 'colspan', 'rowspan','width','summary','id']
    }else if(tagNm == 'area'){
      defaults.allowedAttributes[tagNm] = ['shape','coords', 'class','data-idx','data-site','alt','id','href']
    }else if(tagNm == 'iframe') {
      defaults.allowedAttributes[tagNm] = ['width', 'height', 'src', 'title', 'frameborder', 'allow', 'allowfullscreen']
    }else if(tagNm == 'col') {
      defaults.allowedAttributes[tagNm] = ['class','style', 'colspan', 'width','id', 'span']
    }else if(tagNm == 'font') {
      defaults.allowedAttributes[tagNm] = ['color']
    }else if(tagNm == 'li'){
      defaults.allowedAttributes[tagNm] = ['class','style', 'rel','width','onclick','id']
    }else{
      defaults.allowedAttributes[tagNm] = ['class','style','width','summary','name','id']
    }
  }
}

export const boardDefaults = JSON.parse(JSON.stringify(defaults))
boardDefaults.allowedAttributes.a = [...aTageAllowAttr, 'class','style', 'rel', 'href','width','summary','id']
// 이미지 tag: src=data:image 형식 때문에 추가
boardDefaults.allowedSchemesByTag.img = ['data', 'https', 'http']

defaults.allowVulnerableTags=true

Vue.use(VueSanitize,defaults);